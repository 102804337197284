/**
 * Created by angel on 4/07/17.
 */
import { I2faType } from '../services';
import { isValidURL } from '../utils/url.utils';
import { Serializable } from './serializable.class';

// sorry for this hardcoding :(
export const POD_MASTER_ACCOUNT_ID = '11111111-1111-1111-1111-111111111111';

export interface IAccountAddress {
  fullName: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressStateOrRegion: string;
  addressPostalCode: string;
  addressPhoneNumber: string;
  addressCountry: string;
  taxIdNumber: string;
  default: boolean;
  billingDisclaimer: string;
}

export interface IAccountBranding {
  domainURL: string;
  supportPageMessage: string;
  loginPageMessage: string;
  logoLarge: string;
  logoLogin: string;
  logoSmall: string;
  phoneSupport: string;
  showBranding: string;
  supportEmail: string;
  theme: string;
  notificationSenderEmail: string;
  emailDomain: string;
  emailDomainStatus: string;
  pageTitle: string;
  favicon: string;
  siteKey: string;
}

export interface IAccountCustomization extends IAccountBranding {
  whitelabel: boolean;
}

export interface IAccountTag {
  key: string;
  label: string;
  slug: string;
}

export interface IAccountMeta {
  location: string;
  sectors: Array<string>;
}

export enum SecurityServiceType {
  VPN_CLIENT_ACCESS = 'vpn_client_access',
  VPN_IPSEC = 'vpn_ipsec',
  VPN_ACL = 'vpn_acl',
  DESTINED_IP_RANGE = 'destined_ip_range',
}

export interface ISecuritySettingRange {
  _id?: string;
  address: string;
  netmask: number;
  hostMin?: number;
  hostMax?: number;
  usableHosts?: number;
}

export interface ISecuritySetting {
  poolId: string;
  name: string;
  type: SecurityServiceType;
  carrier: string;
  apns: string[];
  ranges: ISecuritySettingRange[];
}

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
}

export enum AccountType {
  MASTER = 'master',
  RESELLER = 'reseller',
  CUSTOMER = 'customer',
}

export enum DataType {
  XML = 'XML',
  JSON = 'JSON',
}
export interface AlertSettings {
  url: string;
  dataType: DataType;
}

export interface SalesData {
  salesOrganization: string;
  distributionChannel: string;
  division: string;
  incoterms1: string;
  incoterms2: string;
  partnerRoles: Record<string, string[]>;
  currency: string;
  contactPerson: string;
}

export interface ProfileType {
  _id: any;
  value: string;
  group: string;
}

export interface GdBillingSettings {
  customerNumber: string;
  salesData: Array<SalesData>;
  parent: Array<any>;
  deletionFlag: string;
  sortField: string;
  billingBlock: string;
  orderBlock: string;
  profileTypes: Array<ProfileType>;
}

export interface TIPSubscriber {
  id: { type: Number };
  name: { type: String };
  description: { type: String };
}

export class Account extends Serializable {
  public static STATUS_ARRAY: Array<any> = [
    {
      value: AccountStatus.INACTIVE,
      label: 'status.inactive',
    },
    {
      value: AccountStatus.ACTIVE,
      label: 'status.active',
    },
    {
      value: AccountStatus.SUSPENDED,
      label: 'status.suspended',
    },
  ];

  id: string;
  name: string;
  legalName: string;
  defaultEmail: string;
  defaultSmsOrigin: string;
  defaultCallOrigin: string;
  resellerId: string;
  __v: number;
  modified: string;
  created: string;
  operations: any;
  numProducts: string;
  numUsers: string;
  branding: IAccountBranding;
  customization: IAccountCustomization;
  whitelabel: boolean;
  type: AccountType;
  timezone: string;
  status: AccountStatus;
  child: number;
  userIds: any[];
  products: any[];
  preactivation: any;
  lastLogin: any;
  currency: string;
  language: string;
  charts: Array<any>;
  addresses: Array<IAccountAddress>;
  dashboardWidgets: Array<any>;
  billing: {
    type: string;
    balance: number;
  };
  ztp: {
    connioAccountId: string;
  };
  tags: Array<IAccountTag>;
  esimTags: Array<IAccountTag>;
  securitySettings: ISecuritySetting[];
  meta: IAccountMeta;
  twoFaType: I2faType;
  gdBillingSettings?: GdBillingSettings;
  alertSettings?: AlertSettings;
  signalCheckerFeature?: string;
  zoneSchemeLimits: [
    {
      schemeId: {
        type: String;
      };
      schemeName: {
        type: String;
      };
      zones: [
        {
          zoneId: { type: String };
          zoneName: { type: String };
        }
      ];
    }
  ];
  TIPSubscriber: TIPSubscriber[];

  public getResourceName() {
    return 'accounts';
  }

  public getIdKey(): string {
    return 'id';
  }

  public isMaster(): boolean {
    return this.type === AccountType.MASTER;
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }
}

export function isValidAlertSettings(alertSettings: AlertSettings): boolean {
  return alertSettings?.dataType in DataType && isValidURL(alertSettings?.url);
}

export function isEmptyAlertSettings(alertSettings: AlertSettings): boolean {
  return !alertSettings?.dataType && !alertSettings?.url;
}
