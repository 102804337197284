import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, throwError, timer } from 'rxjs';
import {
  catchError,
  filter,
  retryWhen,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { USE_API_HOST } from './api.service';
import {
  AUTH_TOKEN_STORAGE_KEY,
  IAuthTokenResponseLogged,
  SessionService,
} from './session.service';

export const USE_TOKEN_HEADER = new InjectionToken<string>('USE_TOKEN_HEADER');
export const USE_INTERCEPTOR_401_EXCLUSION = new InjectionToken<string>(
  'USE_INTERCEPTOR_401_EXCLUSION'
);
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private get sessionService(): SessionService {
    return this.injector.get(SessionService);
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

  private get translate(): TranslateService {
    return this.injector.get(TranslateService);
  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string> =
    new BehaviorSubject<any>(null);

  constructor(
    @Inject(USE_API_HOST) private useApiHost: string,
    @Inject(USE_TOKEN_HEADER) private useTokenHeader: string = 'Authorization',
    @Inject(USE_INTERCEPTOR_401_EXCLUSION)
    private useInterceptor401Exclusion: string = '/token',
    @Inject(Injector) private injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.match(this.useInterceptor401Exclusion)) {
      return next.handle(request);
    }

    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    const setToken = request.url.startsWith(this.useApiHost);
    let requestWithToken = request;

    if (setToken && token) {
      requestWithToken = this.addToken(request, token);
    }

    return next.handle(requestWithToken).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // Unauthorized
          if (err.status === 401) {
            return this.handle401Error(requestWithToken, next);
          }
          // Bad Gateway
          if ([502, 503, 504].includes(err.status)) {
            window.location.href = '/maintenance';
          }
        }
        return throwError(err);
      }),
      retryWhen(errors =>
        errors.pipe(
          switchMap((err, retryAttempt) => {
            // Status 0 is usually for CORS,
            // but we are getting it also on timeouts
            if ([0, 504].includes(err.status)) {
              return timer(Math.pow(2, retryAttempt + 1) * 1000).pipe(
                tap(() => this.toastrService.info('Retrying request'))
              );
            }
            return throwError(err);
          })
        )
      )
    );
  }

  /**
   * This method handles 401 HTTP errors. It attempts to refresh the authentication token if it's not currently refreshing.
   * If the token refresh is successful, it retries the original request with the new token.
   * If the token refresh fails, it logs out the user and redirects them to the login page.
   * If a token refresh is already in progress, it waits for the new token to be available before retrying the request.
   *
   * @param {HttpRequest<any>} request - The original HTTP request that resulted in a 401 error.
   * @param {HttpHandler} next - The next middleware in the HTTP pipeline.
   * @returns {Observable<HttpEvent<any>>} - An Observable that emits the HTTP response or an error.
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // if not refrshing
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.sessionService.refreshToken().pipe(
        // if success, set the token and retry the request
        switchMap((response: HttpResponse<IAuthTokenResponseLogged>) => {
          const { body } = response;

          if (response.status === 200) {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(body.token);
            localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, body.token);

            return next.handle(this.addToken(request, body.token));
          }

          return throwError(new Error('Token refresh failed'));
        }),

        // if error, logout and redirect to login
        catchError(err => {
          this.isRefreshing = false;
          localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
          this.toastrService.info(
            this.translate.instant('message.sessionExpired')
          );
          this.sessionService.logout();
          this.router.navigate(['/login']);
          return throwError(err);
        })
      );
    }

    // If refreshing, wait for the token and retry the request
    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(token => {
        return next.handle(this.addToken(request, token));
      })
    );
  }

  /**
   * This method adds an Authorization header to the HTTP request.
   * The Authorization header uses the Bearer scheme, meaning that the token
   * provided as an argument is expected to be a bearer token.
   *
   * @param {HttpRequest<any>} request - The original HTTP request.
   * @param {string} token - The bearer token to be added to the request.
   * @returns {HttpRequest<any>} - The cloned HTTP request with the Authorization header added.
   */
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        [this.useTokenHeader]: `Bearer ${token}`,
      },
    });
  }
}
