import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFilterComponent } from './date-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        DateFilterComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild()
    ],
    exports: [
        DateFilterComponent
    ],
    providers: []
})
export class DateFilterModule {}
