// https://stackblitz.com/edit/angular-nrta3n?file=src%2Fapp%2Fresettable-input.component.ts

import {
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
} from '@angular/forms';
import {
  Injector,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  Directive,
  Component,
} from '@angular/core';

@Component({
  template: '',
})
export abstract class ControlValueAccessorConnector
  implements ControlValueAccessor
{
  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  @Input()
  formControl: UntypedFormControl;

  @Input()
  formControlName: string;

  @Output() blur = new EventEmitter();

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  get control() {
    return (this.formControl ||
      this.controlContainer.control.get(this.formControlName)) as UntypedFormControl;
  }

  constructor(private injector: Injector) {}

  onTouched = () => {};

  registerOnTouched(fn: any): void {
    this.formControlDirective &&
      this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective &&
      this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective &&
      this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective &&
      this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  onBlur() {
    this.onTouched();
    this.blur.emit();
  }
}
