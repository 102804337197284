/**
 * Created by angel on 22/06/17.
 */
import { ISecuritySetting } from './account.class';
import { Serializable } from './serializable.class';

export interface ISimSetupAlert {
  notification: string;
  type: string;
  limit?: number;
  enabled?: boolean;
  alerttype?: string;
}

export interface ISimSetup {
  accountId: string;
  assetName: string;
  groupName: string;
  alternativeEmail: string;
  alerts: ISimSetupAlert[];
  statusAlerts: ISimSetupAlert[];
  smsAlerts: ISimSetupAlert[];
  dormantFeeAlerts: ISimSetupAlert[];
  tags: Array<ISimTag>;
}

export interface ISimSubscriptionBundle {
  bundleId: string;
  localProductName: string;
  localProductId: string;
  baseProductId: string;
  initialSize: number;
  remainingBytes: number;
  preactivationInitialBytes: number;
  preactivationRemainingBytes: number;
  preactivationInitialSms: number;
  preactivationRemainingSms: number;
  preactivationInitialVoice: number;
  preactivationRemainingVoice: number;
  dataUsed: number;
  startTime: string;
  endTime: string;
  cost: number;
  creditUsed: number;
  perMbCost: number;
  remainingCredit: number;
  pssProducts: {
    PerMbProduct: string;
    DataPoolProduct: string;
  };
  proratedBytes: number;
  smsInitialSize: number;
  remainingSms: number;
  proratedSms: number;
  smsUsed: number;
  smsCreditUsed: number;
  smsPoolCost: number;
  smsCost: number;
  type: string;
}

export interface ZoneSchemeLimits {
  schemeId: string;
  schemeName: string;
  zones: [
    {
      zoneName: string;
      zoneId: string;
      limit: number;
    }
  ];
}

export interface ISimSubscription {
  id: string;
  accountId: string;
  limit: number;
  smsLimit: number;
  bundles: Array<ISimSubscriptionBundle>;
  suspensionFeeDate: string;
  deactivateFeeDate: string;
  transferredDate: string;
  zoneSchemeLimits: Array<ZoneSchemeLimits>;
}

export interface VirtualMSISDN {
  msisdn: string;
  provider: string;
}

export enum SimType {
  SIM = 'SIM',
  ESIM_PROFILE_M2M = 'eSIM Profile M2M',
  ESIM_PROFILE_CONSUMER = 'eSIM Profile Consumer',
}

export enum SimStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
  PREACTIVE = 'preactive',
  TERMINATED = 'terminated',
}

export enum SimImsisType {
  SINGLE_IMSIS = 'singleimsis',
  MULTI_IMSIS = 'multiimsis',
}

export enum CarrierStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
  PREACTIVE = 'preactive',
  TERMINATED = 'terminated',
}

export enum ESimProfileStateM2M {
  ONSTOCK = 'onstock',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  CREATED = 'created',
}
export enum ESimProfileStateConsumer {
  AVAILABLE = 'available',
  LOCKED = 'locked',
  ALLOCATED = 'allocated',
  LINKED = 'linked',
  CONFIRMED = 'confirmed',
  RELEASED = 'released',
  DOWNLOADED = 'downloaded',
  INSTALLED = 'installed',
  ERROR = 'error',
}
export const ESimProfileState = {
  ...ESimProfileStateM2M,
  ...ESimProfileStateConsumer,
};
export type ESimProfileState = ESimProfileStateM2M | ESimProfileStateConsumer;

export enum ESimProfileTypeM2M {
  BOOTSTRAP = 'bootstrap',
  OPERATIONAL = 'operational',
}
export enum ESimProfileTypeConsumer {
  PREINSTALLED = 'preinstalled',
  VIRTUAL = 'virtual',
}
export const ESimProfileType = {
  ...ESimProfileTypeM2M,
  ...ESimProfileTypeConsumer,
};
export type ESimProfileType = ESimProfileTypeM2M | ESimProfileTypeConsumer;
export interface ISimTag {
  key: string;
  value: string;
}

export interface SimImsi {
  carrier: string;
  imsi: string;
  iccid: string;
  msisdn: string;
  root?: boolean;
  slot?: number;
  stockImsi?: string;
}

export class Sim extends Serializable {
  public static readonly KILO_BYTES = 1024;
  public static readonly MEGA_BYTES = Sim.KILO_BYTES * 1024;

  id: string;
  iccid: string;
  type: SimType;
  model: string;
  profileState: ESimProfileState;
  profileType: ESimProfileType;
  bootstrapEid: string;
  linkedEid: string;
  lastEid: string;
  status: SimStatus;
  activationDate: string;
  endTimeContractLength: string;
  subscriptionDate: string;
  suspensionDate: string;
  terminationDate: string;
  reactivationDate: string;
  ownership: string[];
  ownerAccountId: string;
  ownerAccountName: string;
  carriers: Record<string, boolean>;
  carrierStatus: CarrierStatus;
  imsisType: SimImsisType;
  imsis: SimImsi[];
  imsi: string;
  msisdn: string[];
  originalMSISDN: string[];
  virtualMSISDN: VirtualMSISDN[];
  imei: string;
  securityServices?: ISecuritySetting[];
  fixedIPs: {
    carrier: string;
    ip: string;
  }[];
  created: string;
  modified: string;
  setups: Array<ISimSetup>;
  subscriptions: Array<ISimSubscription>;
  zoneSchemeLimits: Array<ZoneSchemeLimits>;
  lastCall: {
    type: string;
    id: string;
    startTime: string;
    endTime: string;
    servingNetwork: {
      mcc: string;
      mnc: string;
    };
    homeNetwork: {
      mcc: string;
      mnc: string;
    };
    ipAddress: string;
    imei: string;
    bytes: number;
    roundedBytes: number;
    duration: number;
    from: string;
    to: string;
    carrier: string;
  };

  lastSMS: {
    type: string;
    carrierId: string;
    carrier: string;
    endTime: string;
    originatingAddress: string;
    destinationAddress: string;
    servingNetwork: {
      mcc: string;
      mnc: string;
    };
  };

  sms: any;
  totalBytes: number;
  totalCost: number;
  trackingCode: string;
  limit: number;
  smsLimit: number;
  rspProvider?: string;

  callbackStatus: number;

  // Own vars
  favorite: boolean;
  currentSetup: ISimSetup; // setups[0]
  costSubscription: ISimSubscription; // subscriptions[0]
  costSubscriptionBundle: ISimSubscriptionBundle; // subscriptions[0].bundles[0]
  applySuscription: ISimSubscription; // subscriptions[1]
  applySuscriptionBundle: ISimSubscriptionBundle; // subscriptions[0].bundles[0]
  outBundleMb: number;
  outBundleCost: number;
  initialSizePercent: number;
  dataUsedPercent: number;
  prorataUsedPercent: number;
  pendingOperation: boolean;
  sid: number;

  public getResourceName(): string {
    return 'assets';
  }

  public getExportPath(): string {
    return this.getBulkPath();
  }

  public getIdKey(): string {
    return 'iccid';
  }

  public init(json: any = {}) {
    super.init(json);

    this.setBundleParams();
  }

  private setBundleParams() {
    this.currentSetup = null;
    this.costSubscription = null;
    this.costSubscriptionBundle = null;
    this.applySuscription = null;
    this.applySuscriptionBundle = null;
    this.initialSizePercent = null;
    this.dataUsedPercent = null;
    this.outBundleMb = null;
    this.prorataUsedPercent = null;

    if (this.setups && this.setups[0]) {
      this.currentSetup = this.setups[0];
    }

    if (this.subscriptions) {
      if (this.subscriptions[0]) {
        this.costSubscription = this.subscriptions[0];

        if (this.costSubscription.bundles && this.costSubscription.bundles[0]) {
          this.costSubscriptionBundle = this.costSubscription.bundles[0];
        }

        if (this.costSubscription.limit && this.costSubscriptionBundle) {
          this.initialSizePercent =
            ((this.costSubscriptionBundle.initialSize || 0) /
              this.costSubscription.limit) *
            100;
          if (this.initialSizePercent > 100) {
            this.initialSizePercent = 100;
          }
          this.dataUsedPercent =
            ((this.costSubscriptionBundle.dataUsed || 0) /
              this.costSubscription.limit) *
            100;
          if (this.dataUsedPercent > 100) {
            this.dataUsedPercent = 100;
          }
          this.prorataUsedPercent =
            ((this.costSubscriptionBundle.proratedBytes || 0) /
              this.costSubscription.limit) *
            100;
          if (this.prorataUsedPercent > 100) {
            this.prorataUsedPercent = 100;
          }
        }

        if (this.costSubscriptionBundle) {
          this.outBundleMb =
            this.costSubscriptionBundle.initialSize -
              this.costSubscriptionBundle.dataUsed >=
            0
              ? 0
              : (this.costSubscriptionBundle.initialSize -
                  this.costSubscriptionBundle.dataUsed) *
                -1;
          this.outBundleCost =
            this.costSubscriptionBundle.remainingCredit >= 0
              ? 0
              : this.costSubscriptionBundle.remainingCredit * -1;
        }
      }

      if (this.subscriptions[1]) {
        this.applySuscription = this.subscriptions[1];

        if (this.applySuscription.bundles && this.applySuscription.bundles[0]) {
          this.applySuscriptionBundle = this.applySuscription.bundles[0];
        }
      }
    }
  }

  public isESimProfile(): boolean {
    return [SimType.ESIM_PROFILE_CONSUMER, SimType.ESIM_PROFILE_M2M].includes(
      this.type
    );
  }
}

export class SimWithSTResponse extends Sim {
  public message: string;
}
