import {
  Component,
  OnChanges,
  Input,
  forwardRef,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import * as moment_ from 'moment-timezone';
const moment = moment_;
import { SessionService } from '../../services/session.service';

export interface IDateFilterType {
  label: string;
  getValue: (data: any) => string;
  start?: boolean;
  end?: boolean;
  show?: () => boolean;
}

@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateFilterComponent),
      multi: true,
    },
  ],
})
export class DateFilterComponent implements OnChanges, ControlValueAccessor {
  @Input() required? = false;
  @Input() showPast = true;
  @Input() showFuture = false;
  @Input() showNever = false;

  // The internal form group
  public innerValue: UntypedFormGroup;

  // A cached value for call onChangeCallback just when change
  private value: any;
  // The timezone for use
  private timezone: string;

  public types: Array<IDateFilterType> = [
    {
      label: 'never',
      getValue: (data: any) => {
        return 'false';
      },
      show: () => this.showNever,
    },
    {
      label: 'betweenInclusive',
      start: true,
      end: true,
      getValue: (data: any) => {
        return data.start && data.end
          ? moment(data.start).tz(this.timezone).toISOString() +
              ',' +
              moment(data.end).tz(this.timezone).toISOString()
          : null;
      },
    },
    {
      label: 'onOrBefore',
      start: true,
      getValue: (data: any) => {
        return data.start
          ? moment().tz(this.timezone).subtract(100, 'years').toISOString() +
              ',' +
              moment(data.start).tz(this.timezone).toISOString()
          : null;
      },
    },
    {
      label: 'onOrAfter',
      start: true,
      getValue: (data: any) => {
        return data.start
          ? moment(data.start).tz(this.timezone).toISOString() +
              ',' +
              moment().tz(this.timezone).add(100, 'years').toISOString()
          : null;
      },
    },
    {
      label: 'today',
      getValue: (data: any) => {
        const today = moment().tz(this.timezone).startOf('day');
        return today.toISOString() + ',' + today.endOf('day').toISOString();
      },
    },
    {
      label: 'thisWeek',
      getValue: (data: any) => {
        const today = moment().tz(this.timezone).startOf('week');
        return today.toISOString() + ',' + today.endOf('week').toISOString();
      },
    },
    {
      label: 'thisMonth',
      getValue: (data: any) => {
        const today = moment().tz(this.timezone).startOf('month');
        return today.toISOString() + ',' + today.endOf('month').toISOString();
      },
    },
    {
      label: 'thisYear',
      getValue: (data: any) => {
        const today = moment().tz(this.timezone).startOf('year');
        return today.toISOString() + ',' + today.endOf('year').toISOString();
      },
    },
    {
      label: 'yesterday',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .subtract(1, 'day')
          .startOf('day');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('day').toISOString()
        );
      },
      show: () => this.showPast,
    },
    {
      label: 'lastWeek',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .subtract(1, 'week')
          .startOf('week');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('week').toISOString()
        );
      },
      show: () => this.showPast,
    },
    {
      label: 'lastMonth',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .subtract(1, 'month')
          .startOf('month');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('month').toISOString()
        );
      },
      show: () => this.showPast,
    },
    {
      label: 'lastYear',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .subtract(1, 'year')
          .startOf('year');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('year').toISOString()
        );
      },
      show: () => this.showPast,
    },
    {
      label: 'tomorrow',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .add(1, 'day')
          .startOf('day');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('day').toISOString()
        );
      },
      show: () => this.showFuture,
    },
    {
      label: 'nextWeek',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .add(1, 'week')
          .startOf('week');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('week').toISOString()
        );
      },
      show: () => this.showFuture,
    },
    {
      label: 'nextMonth',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .add(1, 'month')
          .startOf('month');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('month').toISOString()
        );
      },
      show: () => this.showFuture,
    },
    {
      label: 'nextYear',
      getValue: (data: any) => {
        const yesterday = moment()
          .tz(this.timezone)
          .add(1, 'year')
          .startOf('year');
        return (
          yesterday.toISOString() + ',' + yesterday.endOf('year').toISOString()
        );
      },
      show: () => this.showFuture,
    },
  ];
  public isDisabled: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private session: SessionService
  ) {
    // Set used timezone
    this.timezone = this.session.getTimezone();

    this.innerValue = this.formBuilder.group({
      type: null,
      start: null,
      end: null,
    });

    this.innerValue.valueChanges.subscribe(data => {
      if (data.type) {
        if (data.type.start && !data.start) {
          const todayStart = moment().tz(this.timezone).startOf('day');
          data.start = todayStart.toISOString();
          this.innerValue
            .get('start')
            .setValue(todayStart.format('YYYY-MM-DDTHH:mm'));
        }
        if (data.type.end && !data.end) {
          const todayEnd = moment().tz(this.timezone).endOf('day');
          data.end = todayEnd.toISOString();
          this.innerValue
            .get('end')
            .setValue(todayEnd.format('YYYY-MM-DDTHH:mm'));
        }
        this.writeValue(data.type.getValue(data));
      } else if (this.value) {
        this.writeValue(null);
      }
    });
  }

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  ngOnChanges(changes: SimpleChanges) {
    this.required =
      !!changes.required?.currentValue || changes.required?.currentValue === '';
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    // WORKAROUND: write value is always called a first time with null (https://github.com/angular/angular/issues/14988)
    if (this.value === undefined && value === null) {
      this.value = null;
    } else {
      if (value !== this.value) {
        this.onChangeCallback(value);
      }

      this.value = value;

      if (!value) {
        this.innerValue.reset();
      }

      if (value && !this.innerValue.get('type').value) {
        const split = value.split(',');
        this.innerValue.setValue({
          type: this.types[0],
          start: moment(split[0]).tz(this.timezone).format('YYYY-MM-DDTHH:mm'),
          end: moment(split[1]).tz(this.timezone).format('YYYY-MM-DDTHH:mm'),
        });
      }
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Own methods
}
