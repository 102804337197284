<div class="modal-header">
  <h5 class="modal-title">{{'label.exportDataCsv' | translate}}</h5>
</div>
<form (ngSubmit)="validate()">
  <div class="modal-body">
    <label class="form-label">{{'label.exportRecordsSettings' | translate}}</label>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          value="all"
          id="allRecords"
          name="allRecords"
          [(ngModel)]="model.recordsToExport"
          required
        />
        <label
          class="form-check-label"
          for="allRecords"
        >{{'label.exportAllRecords' | translate}}
          <span
            *ngIf="total"
            class="export-indicator-modal-label"
          >
            ({{total}}{{'label.total' | translate}})
          </span></label>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          value="filtered"
          id="filteredRecords"
          name="filteredRecords"
          [(ngModel)]="model.recordsToExport"
          [disabled]="!filtered"
          required
        />
        <label
          class="form-check-label"
          for="filteredRecords"
        >{{'label.exportAllFilteredRecords' | translate}} <span class="export-indicator-modal-label">({{filtered}}
            {{'label.filtered' | translate}})</span></label>
      </div>
    </div>
    <div
      *ngIf="selected"
      class="mb-3"
    >
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          value="selected"
          id="selectedRecords"
          name="selectedRecords"
          [(ngModel)]="model.recordsToExport"
          required
        />
        <label
          class="form-check-label"
          for="selectedRecords"
        >{{'label.exportAllSelectedRecords' | translate}} <span class="export-indicator-modal-label">({{selected}}
            {{'label.selected' | translate}})</span></label>
      </div>
    </div>

    <label class="form-label">{{'label.exportColumnsSettings' | translate}}</label>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          value="all"
          id="allColumns"
          name="allColumns"
          [(ngModel)]="model.columnsToExport"
          required
        />
        <label
          class="form-check-label"
          for="allColumns"
        >{{'label.exportAllColumns' | translate}} <span class="export-indicator-modal-label">({{allColumns.length}}
            {{'label.columns' | translate}})</span></label>
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          value="visible"
          id="visibleColumns"
          name="visibleColumns"
          [(ngModel)]="model.columnsToExport"
          required
        />
        <label
          class="form-check-label"
          for="visibleColumns"
        >{{'label.exportOnlyVisible' | translate}} <span
            class="export-indicator-modal-label">({{selectedColumns.length}}
            {{'label.columns' | translate}})</span></label>
      </div>
    </div>

    <div *ngIf="submitted">
      <p
        class="text-danger"
        *ngIf="model.recordsToExport && !model.columnsToExport"
      >
        {{'message.addColumnstoo' | translate}}
      </p>
      <p
        class="text-danger"
        *ngIf="!model.recordsToExport && model.columnsToExport"
      >
        {{'message.addRecordstoo' | translate}}
      </p>
    </div>

    <div class="d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="activeModal.dismiss()"
        id="table-export-cancel"
      >{{'label.cancel' | translate}}</button>
      <button
        type="submit"
        class="btn btn-tertiary"
        [disabled]="!model.recordsToExport || !model.columnsToExport"
        id="table-export-exportcsv"
      >{{'label.exportCsv' | translate}}</button>
    </div>
  </div>
</form>