import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SimsService, INetworkCountry, INetworkMccMnc } from '../../services/sims.service';

@Component({
  selector: 'pod-location-selector',
  templateUrl: './location-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationSelectorComponent),
      multi: true
    }
  ]
})
export class LocationSelectorComponent implements ControlValueAccessor {

  // The internal data model
  private innerValue: any;

  public locationSearch: UntypedFormGroup;

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private simsService: SimsService
  ) {}

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
    this.setForm(value);
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  // From ControlValueAccessor interface
  setDisabledState(isDisabled: boolean): void {
    if (this.locationSearch) {
      if (isDisabled) {
        this.locationSearch.disable();
      } else {
        this.locationSearch.enable();
      }
    }
  }

  // Own methods
  private setFormValue(value: {mcc: INetworkCountry, mnc: INetworkMccMnc} = {mcc: null, mnc: null}) {
    if (!this.locationSearch) {
      this.locationSearch = this.formBuilder.group({
        mcc: value.mcc,
        mnc: value.mnc
      });

      this.locationSearch.valueChanges.subscribe(data => {
        let result = null;

        if (data.mcc && data.mcc.countryCode) {
          if (data.mnc && data.mnc.mccMnc) {
            result = data.mnc.mccMnc;
          } else {
            result = this.simsService.getCountryMccsByCountryCode(data.mcc.countryCode).join(',');
          }
        }

        if (result !== this.innerValue) {
          this.innerValue = result;

          if (!data.mcc || !data.mcc.countryCode) {
            this.locationSearch.get('mnc').reset();
          }

          this.onChangeCallback(result);
        }
      });
    } else {
      this.locationSearch.setValue(value);
    }
  }

  private setForm(value: any) {
    if (value && typeof value === 'string') {
      this.simsService.locationFilterDecoder(value)
      .subscribe(
        formData => this.setFormValue(formData)
      );
    } else {
      this.setFormValue();
    }
  }

  public searchMcc = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term => {
          return term.length < 2 ? [] : this.simsService.getCountries().filter(item => {
            return item.countryName.toLowerCase().indexOf(term.toLowerCase()) > -1;
          }).slice(0, 10);
        }
      )
    );
  }

  public formatterMcc = (result: INetworkCountry) => {
    return result.countryName;
  }

  public searchMnc = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(
        term => {
          const mccValue = this.locationSearch && this.locationSearch.get('mcc').value;
          if (term.length < 1 || !mccValue || !mccValue.countryCode) {
            return [];
          } else {
            return this.simsService.getNetworkMccMncsByCountryCode(mccValue.countryCode).filter(networkOfCountry => {
              return networkOfCountry.brand.toLowerCase().indexOf(term.toLowerCase()) > -1;
            }).slice(0, 10);
          }
        }
      )
    );
  }

  public formatterMnc = (result: INetworkMccMnc) => {
    return result.brand;
  }

}
