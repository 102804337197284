import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ILoginCustomization, SessionService } from 'pod-ng-core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class LoginCustomizationResolver  {

  public customization$ = new ReplaySubject<ILoginCustomization>();

  constructor(
    private session: SessionService
  ) {
    this.session.getLoginCustomization()
      .subscribe(
        cust => this.customization$.next(cust),
        null,
        () => this.customization$.complete()
      );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ILoginCustomization> {
    return this.customization$;
  }

}
