import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { LocationSelectorComponent } from './location-selector.component';

@NgModule({
    declarations: [
        LocationSelectorComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbTypeaheadModule,
        TranslateModule.forChild()
    ],
    exports: [
        LocationSelectorComponent
    ],
    providers: []
})
export class LocationSelectorModule {}
