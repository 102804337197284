<div [formGroup]="innerValue">
  <select
    class="form-select"
    formControlName="type"
    [required]="required"
  >
    <option
      [ngValue]="null"
      [selected]="!innerValue.value.type"
    >{{'label.showAll' | translate}}</option>
    <ng-container *ngFor="let type of types">
      <option
        *ngIf="!type.show || type.show()"
        [ngValue]="type"
      >{{('dateFilter.' + type.label) | translate}}</option>
    </ng-container>
  </select>

  <div
    *ngIf="innerValue.value.type?.start"
    class="mt-3"
  >
    <label class="form-label">{{( innerValue.value.type?.end ? 'label.selectTheStartDate' : 'label.selectTheDate') |
      translate}}</label>
    <input
      type="datetime-local"
      class="form-control"
      formControlName="start"
      [required]="required"
    />
  </div>

  <div
    *ngIf="innerValue.value.type?.end"
    class="mt-3"
  >
    <label class="form-label">{{'label.selectTheEndDate' | translate}}</label>
    <input
      type="datetime-local"
      class="form-control"
      formControlName="end"
      [required]="required"
    />
  </div>
</div>
