/**
 * Created by angel on 29/06/17.
 */
import { Injectable, Injector } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  ApiService,
  AUTH_TOKEN_STORAGE_KEY,
  SessionService,
} from 'pod-ng-core';

import { ThemeService } from './theme.service';
import { flatMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  private themeService: ThemeService;
  private googleAnalyticsService: GoogleAnalyticsService;
  private session: SessionService;

  constructor(private router: Router, injector: Injector) {
    this.themeService = injector.get(ThemeService);
    this.googleAnalyticsService = injector.get(GoogleAnalyticsService);
    this.session = injector.get(SessionService);
  }

  private catchActivateError(state: RouterStateSnapshot): Observable<boolean> {
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }

  private catchPermissionError(url: string): boolean {
    this.router.navigate([url]);
    return false;
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)) {
      // logged in so return true
      return this.session.getUserMe().pipe(
        flatMap(user => {
          // Identify user in Google Analytics
          this.googleAnalyticsService.setUsername(user.getId());
          this.googleAnalyticsService.setUserProperties({
            userId: user.getId(),
            accountId:
              (this.session.account && this.session.account.getId()) ||
              undefined,
            roles:
              (user.permissions &&
                user.permissions[0] &&
                user.permissions[0].roles) ||
              undefined,
          });

          // Set theme
          this.themeService.setThemeById(this.session.getAccountTheme());
          this.themeService.setFavicon(this.session.getAccountFavicon());

          return of(!!user);
        }),
        map(logged => {
          let lastChild = state.root;
          while (lastChild.firstChild) {
            lastChild = lastChild.firstChild;
          }
          const data = lastChild.routeConfig.data;
          let permission: string | string[];
          let redirectTo = '';
          if (data) {
            permission = data.permission ? data.permission : null;
            redirectTo = data.redirectTo ? data.redirectTo : '';
          }

          if (permission) {
            return (
              typeof permission === 'string'
                ? this.session.hasPermission(permission)
                : permission.some(p => this.session.hasPermission(p))
            )
              ? true
              : this.catchPermissionError(redirectTo);
          } else {
            return true;
          }
        }),
        catchError(error => this.catchActivateError(state))
      );
    }
    return this.catchActivateError(state);
  }
}
