import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationMessagesComponent } from './validation-messages.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
    declarations: [ValidationMessagesComponent],
    imports: [CommonModule, TranslateModule.forChild()],
    exports: [ValidationMessagesComponent],
    providers: []
})
export class ValidationMessagesModule {}
