<select
  *ngIf="!multiple"
  class="form-select"
  [ngClass]="size ? 'form-select-' + size : ''"
  [formControl]="control"
  (blur)="onBlur()"
>
  <option
    *ngIf="getPlaceholder()"
    [ngValue]="null"
  >{{getPlaceholder()}}</option>
  <ng-content></ng-content>
  <ng-container *ngIf="items$">
    <option
      *ngFor="let item of (items$ | async)"
      [ngValue]="bindValue ? item[bindValue] : item"
    >
      {{bindLabel ? item[bindLabel] : item}}
    </option>
  </ng-container>
</select>

<ng-select
  *ngIf="multiple"
  [formControl]="control"
  (blur)="onBlur()"
  [multiple]="multiple"
  [items]="items$ | async"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [closeOnSelect]="closeOnSelect"
  [placeholder]="getPlaceholder()"
  [dropdownPosition]="dropdownPosition"
>
</ng-select>